import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Seo title="Your enquiry has been sent" description="" image="" pathname={entry.fields.slug} />
      <Nav />
      <div className="w-full py-12 bg-blue">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12 border-b-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-around gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose text-center">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
			}
			fields {
				slug
			}
      html
		}
	}
`